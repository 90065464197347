<template>
  <v-container class="my-6">
    <v-overlay :value="loading">
      <v-row>
        <v-col class="text-center">
          <v-progress-circular
            :width="3"
            color="red"
            indeterminate
          ></v-progress-circular>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="font-weight-bold">
          <p>{{ $t("Checkout.wait") }}</p>
        </v-col>
      </v-row>
    </v-overlay>

    <n-panels>

      <template #center>
        <v-card class="shadow-sm">
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('PaymentMethod.payment') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              @click="() => $router.push(`/orders/${order.ocId}/payments`).catch(() => {})">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <router-view :order="order" v-if="!loading"></router-view>
          </v-card-text>
        </v-card>
      </template>

      <template #right>
        <p class="font-weight-bold mb-6">
          {{ $t("Checkout.youOrder") }}
        </p>

        <template v-for="(item, index) in items">
          <div :key="`p-${index}`" class="d-flex justify-space-between mb-2">
            <p class="mb-0">
              <span class="font-weight-bold">{{ item.cantidadCaja }}</span> x
              {{ item.producto }}
            </p>
            <p class="mb-0">$ {{ (item.precioUnitario || 0).toFixed(2) }}</p>
          </div>
          <div class="caption primary--text" v-if="item.fechaApt != null && item.fechaApt != undefined && item.fechaApt != ''"><v-icon x-small color="primary" class="pr-2">mdi-calendar</v-icon>{{ item.fechaApt }}</div>

          <v-divider :key="`s-${index}`" class="my-2" />
        </template>

        <div class="d-flex justify-space-between mb-2">
          <p class="mb-0 grey--text text--darken-2">
            {{ $t("Checkout.subtotal") }}:
          </p>
          <p class="mb-0 font-weight-bold">
            $ {{ (subtotal || 0).toFixed(2) }}
          </p>
        </div>

        <div class="d-flex justify-space-between mb-2">
          <p class="mb-0 grey--text text--darken-2">
            {{ $t("Checkout.discount") }}:
          </p>
          <p class="mb-0 font-weight-bold">
            $ {{ (order.descuento || 0).toFixed(2) }}
          </p>
        </div>

        <div class="d-flex justify-space-between mb-2">
          <p class="mb-0 grey--text text--darken-2">
            {{ $t("Checkout.tax") }}:
          </p>
          <p class="mb-0 font-weight-bold">
            $ {{ (iva || 0).toFixed(2) }}
          </p>
        </div>

        <div class="d-flex justify-space-between mb-2">
          <p class="mb-0 grey--text text--darken-2">
            {{ $t("Checkout.ieps") }}:
          </p>
          <p class="mb-0 font-weight-bold">
            $ {{ (ieps || 0).toFixed(2) }}
          </p>
        </div>

        <div class="d-flex justify-space-between mb-2">
          <p class="mb-0 grey--text text--darken-2">
            {{ $t("Checkout.shipping") }}:
          </p>
          <p class="mb-0 font-weight-bold">-</p>
        </div>

        <v-divider class="my-4" />

        <div class="d-flex justify-space-between mb-2">
          <p class="mb-0 font-weight-bold">{{ $t("Checkout.total") }}:</p>
          <p class="mb-0 font-weight-bold">
            $ {{ (order.total || 0).toFixed(2) }}
          </p>
        </div>
      </template>

    </n-panels>

  </v-container>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import WSocket from "../../plugins/wsocket";
import { mapState } from 'vuex';
import NPanels from '../../components/newgen/NPanels.vue';

const TIPO_AVISO = {
  [-1]: "El cobro no ha sido aceptado!",
  0: "Aceptado por el comprador, por favor espere un momento!",
  1: "El cobro ha sido acreditado!",
  2: "El cobro ha sido rechazado!",
  3: "Se ha realizado una devolucion por una transaccion no acreditada!",
  4: "El cobro ha sido pospuesto!",
  6: "Se ha realizado una devolucion por una transaccion acreditada!",
};

export default {
  components: {
    NPanels
  },
  computed: {
    ...mapState({
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
    subtotal(){
      return _.sumBy(this.items, (i) => Math.round(i.subtotal * 100) / 100);
    },
    iva(){
      return _.sumBy(this.items, (i) => Math.round(i.importeIva * 100) / 100);
    },
    ieps(){
      return _.sumBy(this.items, (i) => Math.round(i.montoIeps * 100) / 100);
    }
  },
  data() {
    return {
      order: {},
      items: [],
      loading: true,
      socket: new WSocket(),
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      // SE OBTIENE EL IDENTIFICADOR DE LA ORDEN DE COMPRA
      let ocId = this.$route.params.ocId;

      // SE ACTIVA LA ANIMACION DE CARGA
      this.loading = true;

      let url = `api/v1`;

      if (this.isAuthenticated) {
        url += `/orders/${ocId}`;
      } else {
        url += `/public/orders/${ocId}`;
      }

      // SE REALIZA PETICION AL CONTROLLER PRIVADO DE ORDENES DE COMPRA
      axios
        .get(url)
        .then((res) => {
          // SE OBTIENE EL CUERPO DE LA RESPUESTA DE MANERA SEGURA
          let body = _.get(res, "data.data");

          // SE VERIFICA QUE SE HAYA RECIBIDO RESPUESTA
          if (body) {
            // SE OBTIENE LA ORDEN DE COMPRA DE MANERA SEGURA
            let order = _.get(body, "order");

            let estatusOcId = _.get(order, "tblLgtEstatusOc.estatusOcId", "00");

            // SE VERIFICA QUE EL STATUS DE LA ORDEN SE ENCUENTRE PENDIENTE
            if (estatusOcId == "00") {
              // SE ASIGNA LA ORDEN DE COMPRA A VARIABLE REACTIVA
              this.order = order;

              // SE OBTIENE EL DETALLE DE LA ORDEN DE COMPRA DE MANERA SEGURA
              let items = _.get(body, "details", []);

              // SE ASIGNA EL DETALLE DE LA ORDEN DE COMPRA A VARIABLE REACTIVA
              this.items = items;

              // SE DESHABILITA LA ANIMACION DE CARGA
              this.loading = false;

              // SE GENERA EL MENSAJE DE COBRO
              this.socketConnection(order);
            } else {
              // SI EL STATUS DE LA ORDEN DE COMPRA ES DIFERENTE A PENDIENTE
              // SE ENVIA A LA PANTALLA DE DETALLES DE ORDEN DE COMPRA

              this.$router.push(`/orders/${order.ocId}`).catch(() => {});
            }
          }
        })
        .catch((err) => {
          this.$console.error(err);

          this.order = {};
          this.items = [];
          this.loading = false;
        });
    },
    socketConnection(order) {
      console.log("connecting");
      // SE OBTIENE EL IDENTIFICADO DE LA ORDEN DE COMPRA
      let ocId = _.get(order, "ocId");

      // SE CREA UNA NUEVA CONFIGURACION DE SOCKET
      let socket = new WSocket();

      // SE ESTABLECE LA URL DE CONEXION AL SOCKET
      socket.baseUrl = process.env.VUE_APP_BASE_URL + "/wellcom";

      // SE ESTABLECE EL NOMBRE DE USUARIO PARA AUTENTICACION DEL SOCKET
      socket.username = _.get(this.order, "tblUsrUsuario.usuario");

      // SE ESTABLECE LA FUNCION QUE SE EJECUTARA EN CASO DE QUE LA CONEXION HAYA SIDO ABIERTA
      socket.onopen = () => {
        // SE REALIZA LA SUSCRIPCION AL SERVICIO DEL CHAT
        socket.subscribe(`/topic/payments/${ocId}`, (args) => {
          if (args != 0) {
            //console.log('[PAYMENT]', args);

            this.$dialog({
              title: "Verificacion de cobro",
              text: TIPO_AVISO[args] || args,
            }).then(() => {
              // DOES NOTHING
            });

            if (args == 1) {
              // AL RECIBIR EL PAGO SE DESUSCRIBE DEL SOCKET
              socket.unsubscribe(`/topic/payments/${ocId}`);

              // SI EL STATUS DE LA ORDEN DE COMPRA ES DIFERENTE A PENDIENTE
              // SE ENVIA A LA PANTALLA DE DETALLES DE ORDEN DE COMPRA

              this.$router.push(`/orders/${order.ocId}`).catch(() => {});
            }
          }
        });
      };

      socket.onclose = () => {
        // DOES NOTHING
      };

      // SE ABRE LA CONEXION
      socket.connect();
    }
  }
};
</script>